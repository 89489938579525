body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a,
p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
button,
input,
div,
b {
  font-family: "Poppins", sans-serif;
}
a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
  color: inherit;
}
legend {
  float: none !important;
}

.resImg {
  overflow: hidden;
}
.resImg img {
  max-width: 100%;
  vertical-align: middle;
}
.wrapper {
  padding: 40px 15px !important;
}
@media (max-width: 480px) {
  .wrapper {
    padding: 20px 12px !important;
  }
}

//Border radius=======================================
.border-radius-1 {
  border-radius: 4px !important;
}
.border-radius-2 {
  border-radius: 8px !important;
}
.border-radius-3 {
  border-radius: 12px !important;
}
.border-radius-4 {
  border-radius: 16px !important;
}
.border-radius-5 {
  border-radius: 20px !important;
}

// =======================FONT SIZE======================
// =======================FONT  ======================
.fc-grey {
  color: #999999 !important;
}
.fc-green {
  color: #47a738;
}
.fc-primary {
  color: $primary !important;
}

.f-s-10 {
  font-size: 10px !important;
}
.f-s-12 {
  font-size: 12px !important;
}
.f-s-14 {
  font-size: 14px !important;
}
.f-s-16 {
  font-size: 16px !important;
}
.fm-poppins {
  font-family: "Poppins", sans-serif !important;
}
.fm-pt-mono {
  font-family: "PT Mono", "Poppins", sans-serif !important;
}
.fm-dm-serif {
  font-family: "Poppins", sans-serif !important;
}
.fm-urbanist {
  font-family: "Urbanist", sans-serif !important;
}

.fw-m-b {
  font-weight: 500;
}
.fw-semi-b {
  font-weight: 600;
}
.fw-bold {
  font-weight: 700;
}
.heading-1 {
  font-size: 42px;
  font-family: "Poppins", sans-serif;
}
.heading-2 {
  font-size: 38px;
  font-family: "Poppins", sans-serif;
}
.heading-3 {
  font-size: 26px;
  font-family: "Poppins", sans-serif;
}
.heading-4 {
  font-size: 22px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.sub-heading-1 {
  font-size: 24px;
  @extend .fw-m-b;
}
.sub-heading-2 {
  font-size: 18px;
  @extend .fw-m-b;
}
.des-1 {
  font-size: 22px;
}
.des-2 {
  font-size: 18px;
}
.card-h {
  font-size: 22px;
  @extend .fw-semi-b;
}
.card-h-2 {
  font-size: 18px;
  @extend .fw-semi-b;
}
.card-des {
  font-size: 14px;
  @extend .fw-m-b;
}
.c-text-fade {
  color: #414042;
}
.c-text-primary {
  color: $primary !important;
}
@media (max-width: 480px) {
  .taj-1 {
    font-size: 52px;
  }
  .heading-1 {
    font-size: 28px;
    font-weight: bold;
  }
  .heading-2 {
    font-size: 24px;
    font-weight: 700;
  }
  .heading-3 {
    font-size: 22px;
  }
  .sub-heading-1 {
    font-size: 18px;
    font-weight: 600;
  }
  .sub-heading-2 {
    font-size: 16px;
    font-weight: 700;
  }
  .des-1 {
    font-size: 12px;
  }
  .des-2 {
    font-size: 14px;
  }
  .card-h {
    font-size: 14px;
    font-weight: 600;
  }
  .card-h-2 {
    font-size: 12px;
    font-weight: 600;
  }

  .card-des {
    font-size: 12px;
  }
  .w-xs-100 {
    width: 100% !important;
  }
}

/* margin---------------------------------- */

.mt-175 {
  margin-top: 175px !important;
}
.mt-130 {
  margin-top: 130px !important;
}
.mt-75 {
  margin-top: 75px !important;
}
.mt-60 {
  margin-top: 60px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mb-60 {
  margin-bottom: 60px !important;
}
/* padding--------------------------------- */
.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.pd-t-40 {
  padding-top: 40px !important;
}
.pd-t-80 {
  padding-top: 80px !important;
}
.pd-t-60 {
  padding-top: 60px !important;
}
.pd-t-120 {
  padding-top: 120px !important;
}
.pd-t-150 {
  padding-top: 150px !important;
}

.height-100 {
  height: 100% !important;
}
// LINKS=====================
.clear-link {
  text-decoration: none;
}
.clear-link:hover {
  text-decoration: none;
}
.pos-relative {
  position: relative !important;
}
.fade-link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.5);
  transition: all 0.4s;
}
.fade-link:hover {
  color: rgba(0, 0, 0, 1);
}
.dark-link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.8);
  transition: all 0.4s;
}
.dark-link:hover,
.dark-link.active {
  color: rgba(0, 0, 0, 1);
}
.bright-link {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.75);
  transition: all 0.4s;
}
.bright-link:hover {
  color: rgba(255, 255, 255, 1);
}
.no-legend fieldset {
  legend {
    display: none;
  }
  top: 0;
}

.p-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
// OTHERS+++++++++++++++++++++++++++++++++++++++++++++++++++++

.c-bg-primary {
  background-color: $primary !important;
}

.c-bg-secondary {
  background-color: $secondary1 !important;
}
.bg-secondary-1 {
  background-color: $secondary1 !important;
}
.bg-secondary-2 {
  background-color: $secondary2 !important;
}

@media (max-width: 480px) {
  .resImg {
    max-width: 300px !important;
  }
}
.text-ellipse {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.w-250 {
  width: 250px;
}
.visa-apply-stepper .MuiStepLabel-root {
  .MuiStepLabel-iconContainer.Mui-active {
    .MuiStepIcon-root {
      color: $second;
    }
  }
  .MuiStepLabel-labelContainer .MuiStepLabel-label.Mui-active {
    color: $second;
  }
}
.min-h-100vh {
  min-height: 100vh;
}
.content-height {
  min-height: calc(100vh - 90px);
}
.content-height-2 {
  min-height: calc(100vh - 200px);
}
.standard-card {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 20px;
  color: #010101;
  transition: all 0.3s;
  height: 100%;
  .tag {
    background: rgba(163, 94, 154, 0.1);
    padding: 4px 10px;
    display: inline-block;
    font-size: 12px;
    transition: all 0.3s;
    span {
      transition: all 0.3s;
    }
  }
  .title {
    // color: #010101;
    font-weight: 700;
    transition: all 0.3s;
  }
  p {
    color: #010101a8;
    transition: all 0.3s;
  }
  a {
    color: $primary;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    transition: all 0.3s;
  }
  &:hover,
  &.hovered {
    background-color: $primary;
    color: #fff;
    .tag {
      background-color: rgba(255, 255, 255, 0.1);
      span {
        color: #fff;
      }
    }
    .title {
      color: #fff;
    }
    p {
      color: #ffffffa8;
    }
    a {
      color: #ffffffa8;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
