$primary: #a0489e;
$secondary: #333333;
$secondary1: #3c3a3c;
$secondary2: #f0efe9;
$second: #f46412;
$whitePrimary: rgba(255, 255, 255, 1);
$whiteSecondary: rgba(255, 255, 255, 0.85);
$whiteSecondary2: rgba(255, 255, 255, 0.65);
$blackPrimary: rgba(0, 0, 0, 1);
$blackSecondary: rgba(0, 0, 0, 0.85);
$blackSecondary2: rgba(0, 0, 0, 0.65);
$blackSecondary3: rgba(0, 0, 0, 0.35);
$blackSecondary4: rgba(0, 0, 0, 0.18);
$grey1: #fafafa;
$grey2: #ebe9e9;
$grey3: #7d7d7d;
