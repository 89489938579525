@import "function", "variables", "common";

/* 
    NAVBAR ********************************
*/
.main-navbar {
  position: relative;
  background-color: $primary;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  .logo {
    width: 100px;
  }
  .right-side {
    display: flex;
  }
  .c-menu {
    // border-left: 1px solid #fefefe;
    padding-left: 5px;
    cursor: pointer;
    display: flex;
  }
}
.menu-link {
  padding: 7px 10px;
  transition: 0.4s;
  color: #000;
  width: 100%;
  &.active,
  &:hover {
    background-color: $primary;
    color: #fff;
  }
}
// home navbar
.home-navbar {
  position: relative;
  width: 100%;
  padding: 0 15px;
  background-color: $primary;
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;

  .left-sec {
    padding: 8px 0;
    .home-nav-item {
      color: #fff;
      padding: 4px 10px;
      margin-right: 10px;
      border-radius: 30px;
      border-bottom: 2px solid transparent;
      transition: 0.4s;
      &.active,
      &:hover {
        border-bottom-color: #fff;
      }
    }
  }
}
// transparent-navbar
.transparent-navbar {
  position: relative;
  width: 100%;
  padding: 0 15px;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  z-index: 100;
  @media (min-width: 1200px) {
    width: 80%;
  }
  .left-sec {
    padding: 8px 0;
    .home-nav-item {
      color: #fff;
      padding: 4px 10px;
      margin-right: 10px;
      border-radius: 30px;
      border-bottom: 2px solid transparent;
      transition: 0.4s;
      &.active,
      &:hover {
        border-bottom-color: #fff;
      }
    }
  }
}

// Desktop
.standard-navbar {
  position: relative;
  width: 100%;
  padding: 0 15px;
  background-color: #fff;
  padding: 20px 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 100;
  .left-sec {
    padding: 8px 0;
    .home-nav-item {
      color: inherit;
      margin-right: 10px;
      border-radius: 30px;
      transition: 0.4s;
    }
    ul li {
      position: relative;
      transition: all 0.4s;
      padding: 6px 10px;
      svg {
        transition: all 0.4s;
      }
      & > ul {
        position: absolute;
        top: 100%;
        right: 0;
        background-color: #fff;
        color: #212529;
        z-index: 9999;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        transition: 0.4s;
        transform: translateY(10%);
        opacity: 0;
        visibility: hidden;
        z-index: -9999;
      }
      &:hover {
        a > svg {
          transform: rotate(180deg);
        }
        // background-color: inherit;
        & > ul {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
          z-index: 9999;
        }
      }
    }
  }

  &.transparent {
    background-color: transparent;
    color: #fff;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
}

// Mobile & Tablet
.standard-navbar-drawer {
  li {
    padding: 0;
  }
  a {
    padding: 6px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  svg {
    transition: all 0.4s;
  }
  &:hover svg {
    transform: rotate(-180deg);
  }
  .submenu {
    ul {
      padding: 0 16px;
    }
    > .MuiTouchRipple-root {
      display: none;
    }
    & > ul {
      display: none;
    }
    &:hover {
      & > ul {
        display: block;
      }
    }
  }
}

.home-header {
  background-image: url(../assets/img/static/bg/homeBg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff;
  padding-top: 30px;
  position: relative;
  .home-header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
  }
  .home-header-content {
    position: relative;
    z-index: 2;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 576px) {
      width: 100%;
      h5 {
        font-size: 18px;
      }
    }
    .homeHeading {
      color: #fff;
      font-size: 35px;
      font-family: "Poppins", "sans-serif";
      margin-top: 30px;
      margin-bottom: 30px;
      //   text-align: center;
      line-height: 1.5;
      @media (max-width: 576px) {
        font-size: 32px;
      }
      @media (max-width: 480px) {
        margin-top: 10px;
        font-size: 28px;
      }
      @media (max-width: 375px) {
        font-size: 22px;
      }
    }
  }
}

//actually home2
.home-content {
  background-image: url(../assets/img/static/bg/city.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.home-heading {
  color: #fff;
  font-size: 45px;
  font-family: "Noto Serif", "Poppins", "sans-serif";
  margin-top: 30px;
  margin-bottom: 30px;
  //   text-align: center;
  line-height: 1.5;
}
.home-f-links {
  padding: 10px 12px;
  margin: 0 10px 10px 0;
  border-radius: 8px;
  transition: 0.4s;
  background-color: #f7f7f7;
  color: #9f9f9f;
  &.active,
  &:hover {
    background-color: $primary;
    color: #fff;
  }
}

.footer {
  // margin-top: 8px;
  .upper {
    background-color: $primary;
    color: #fff;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 10px 0 0 10px;
    a {
      margin-bottom: 10px;
      margin-right: 10px;
    }
  }
  .lower {
    padding: 10px;
    background-color: #000;
    p {
      color: #fff;
      margin-bottom: 0;
    }
  }
}
.left-carousel-container {
  width: 100%;
  height: 100%;
  background-color: $primary;
  background-image: url(../assets/img/static/bg/designedBg.png);
  background-position: top left;
  background-size: 50%;
  background-repeat: no-repeat;
}
.lef-auth-swiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #fff;
}

/* 
NEWS feed
*/
.new-feed-banner-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 250px;
  overflow: hidden;
  img {
    width: 100%;
    // object-fit: contain;
  }
  .banner-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .details {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 10%;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .hed1 {
      color: #fff;
      font-size: 24px;
    }
    .badge-container {
      display: flex;
      .c-badge {
        padding: 4px 6px;
        border-radius: 6px;
        background-color: rgba(255, 255, 255, 0.3);
        color: #fff;
        margin-right: 10px;
        font-size: 12px;
        &.active {
          background-color: blue;
        }
      }
    }
    .hed2 {
      font-size: 24px;
      margin-top: 10px;
      color: #fff;
      font-weight: 400;
    }
    .desc {
      color: rgba(255, 255, 255, 0.7);
      font-size: 14px;
      max-width: 600px;
    }
    .banner-link {
      text-decoration: underline;
      font-size: 14px;
      color: #fff;
      transition: 0.4s;
      display: inline-block;
      &:hover {
        transform: scale(1.01);
      }
    }
  }
  @media (max-width: 480px) {
    min-height: 200px;
    .details {
      left: 10px;
    }
    .hed1,
    .hed2 {
      font-size: 20px !important;
      font-weight: 500 !important;
    }
    .hed2 {
      width: 280px;
      overflow: hidden;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .desc {
      display: none;
    }
    img {
      min-height: 200px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
.news-feed-swiper .swiper-pagination {
  text-align: right;
  padding-right: 30px;
  .swiper-pagination-bullet {
    background-color: rgba(255, 255, 255, 0.7);
  }
}

.admin-login-page {
  min-height: 100vh;
  background-color: $primary;
  background-image: url(../assets/img/static/bg/city.png);
  background-position: top left;
  background-size: 60% 100%;
  background-repeat: no-repeat;
  .r-footer-items {
    display: none;
  }
}
.admin-login-left-side {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .footer-items {
    position: absolute;
    left: 20px;
    bottom: 20px;
    padding: 5px 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    a {
      color: #fff;
      margin-right: 12px;
    }
  }
}
.admin-login-card {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 30px;
  background-color: #fff;
  margin-top: 30px;
  margin-bottom: 30px;
}
@media (max-width: 600px) {
  .admin-login-page {
    background-size: 100% 100%;
  }
  .admin-login-left-side {
    .resImg {
      width: 100px;
      margin-top: 15px;
    }
  }
  .footer-items {
    display: none;
  }
  .r-footer-items {
    padding: 5px 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    text-align: center;
    display: block !important;
    a {
      color: #fff;
      margin-right: 12px;
    }
  }
}

.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #efefef;
  margin-top: 5px;
  .header-link {
    color: #717171;
    padding: 18px;
    display: inline-block;
    font-size: 16px;
    &.active {
      color: #fff;
      background-color: $primary;
    }
  }
  .right-side {
    padding: 10px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
}
.search-box {
  display: flex;
  border: 1px solid #717171;
  border-radius: 5px;
  background-color: #fff;
  height: 55px;
  min-width: 300px;
  align-items: center;
  padding: 0 10px;
  input {
    flex: 1 0 auto;
    border: none;
    outline: none;
  }
}
.booking-data-card {
  border: 1px solid #c1c1c1;
  background-color: #fff;
  border-radius: 6px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  margin-right: 15px;
  margin-bottom: 15px;
  .heading {
    text-transform: uppercase;
    font-size: 14px;
    color: #717171;
    margin-bottom: 10px;
  }
  .app-title {
    color: #717171;
    font-size: 14px;
    margin-bottom: 5px;
  }
  .c-avatar {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.active {
      background-color: #c1c1c1;
    }
  }
}

.table-header-link {
  padding: 10px 25px;
  font-size: 16px;
  display: inline-block;
  color: #717171;
  background-color: #fff;
  border-top-left-radius: 35px;
  border-bottom-right-radius: 35px;
  transition: 0.4s;
  margin-right: 15px;
  cursor: pointer;
  &.active,
  &:hover {
    background-color: $primary;
    color: #fff;
  }
}

.admin-profile-picture {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  padding: 5px;
  max-width: 300px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .camera-icon {
    position: absolute;
    bottom: 0px;
    width: 38px;
    height: 38px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    cursor: pointer;
  }
}

.hidden-file-input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  // visibility: hidden;
  z-index: 5;
}
.news-feed-layout {
  position: relative;
}
.custom-search {
  display: flex;
  width: 300px;
  z-index: 100;
  padding: 6px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  align-items: center;
  input {
    flex: 1 0 auto;
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
    padding-left: 4px;
  }
}
@media (max-width: 480px) {
  .custom-search {
    left: 0;
    right: 0;
  }
}

.service-card {
  // margin-left: 20px;
  // margin-top: 20px;
  position: relative;
  // width: 230px;
  height: 100%;
  border-radius: 16px;
  background-color: #e9e9e9;
  transition: 0.4s ease;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 4px 12px 12px 12px;
  color: #000;

  .forward-icon {
    display: inline-block;
    margin-left: auto;
    color: rgba(0, 0, 0, 0.3);
  }
  .service-icon {
    margin-bottom: 5px;
    display: inline-block;
    color: #000;
  }
  .content-sec {
    margin-top: 30px;
    color: #000;
    h5 {
      font-size: 18px;
    }
    p {
      font-size: 14px;
      margin-bottom: 0;
      color: rgba(0, 0, 0, 0.6);
    }
  }
  &:hover {
    background-color: $primary;
    .forward-icon {
      color: rgba(255, 255, 255, 0.6);
    }
    .content-sec {
      color: #fff;
      p {
        color: rgba(255, 255, 255, 0.6);
      }
    }
    .service-icon {
      color: #fff;
    }
  }
}
.info-bg {
  background-color: $primary;
  background-image: url(../assets/img/static/bg/city2.png);
  position: relative;
  padding: 0 20px;
  .info-bg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $primary;
    opacity: 0.75;
  }
  .info-content {
    position: relative;
    z-index: 2;
    margin-left: auto;
    margin-right: auto;
    color: #fff;
    width: 80%;

    @media (max-width: 480px) {
      width: 100%;
    }

    .info-right-image {
      height: 480px;
      object-fit: contain;
      object-position: center bottom;
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -o-transform: translateX(-50%);
    }
  }
}

.select-country {
  padding: 60px 12px;
}
.country-card {
  // width: 210;
  // height: 215px;
  padding: 20px 15px;
  background-color: #e9e9e9;
  border: 2px solid #a9a9a9;
  border-radius: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  .country-name {
    color: #212121;
    font-size: 18px;
    margin-top: 14px;
    margin-bottom: 0;
    line-height: 1.2;
  }
  a {
    color: #373737;
    line-height: 1.2;
  }
}
.home-country-swiper {
  padding-bottom: 60px;
  .swiper-pagination.swiper-pagination-clickable {
    bottom: 0px;
    text-align: start;
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      width: 80px;
      border-radius: 8px;
      background-color: #e7e7e7;
    }
    .swiper-pagination-bullet {
      width: 30px;
      border-radius: 8px;
      transition: 0.4s ease-in-out;
      background-color: #656565;
      opacity: 1;
    }
  }
}
.subscribe-bg,
.world-map-bg {
  background-image: url(../assets/img/static/bg/worldMap.png);
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  padding: 60px 12px;
  position: relative;
  .container {
    position: relative;
    z-index: 3;
  }
}
.home-contact-bg {
  background-image: url(../assets/img/static/bg/homeBannerContactUs.png);
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  padding: 60px 0;
  margin-top: -1px;
  margin-bottom: -1px;
  position: relative;
  .container {
    position: relative;
    z-index: 3;
  }
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 0;
}

.enjaz-header {
  background-image: url(../assets/img/static/bg/enjazBg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff;
  padding-top: 30px;
  position: relative;
}
.enjaz-header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 1));
}
.enjaz-header-container {
  position: relative;
  z-index: 2;
  height: 400px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 30px;
}
.enjaz-header-content {
  color: #fff;
  max-width: 90%;
  .enjazHeading {
    color: #fff;
    font-size: 35px;
    font-family: "Poppins", "sans-serif";
    //   text-align: center;
    line-height: 1.5;
    @media (max-width: 480px) {
      margin-top: 10px;
      font-size: 28px;
    }
  }
}

.enjaz-family-visa-header {
  background-image: url(../assets/img/static/bg/enjazFamilyVisa.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff;
  padding-top: 30px;
  position: relative;
  background-position: center;
}
.family-visa {
  height: 480px;
}
.v-step {
  display: inline-block;
  height: 40px;
  width: 1px;
  background-color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
}

.c-badge-num {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  margin-bottom: 10px;
}

.banner-header {
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff;
  padding-top: 30px;
  position: relative;
}
.banner-header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 1));
}
.banner-header-container {
  position: relative;
  z-index: 2;
  height: 400px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 30px;
}
.banner-header-content {
  color: #fff;
  max-width: 90%;
  .bannerHeading {
    color: #fff;
    font-size: 35px;
    font-family: "Poppins", "sans-serif";
    //   text-align: center;
    line-height: 1.5;
    @media (max-width: 480px) {
      margin-top: 10px;
      font-size: 28px;
    }
  }
}
.faq-bg {
  background-image: url(../assets/img/static/bg/faq.png);
}
.passport-process-bg {
  background-image: url(../assets/img/static/bg/passportBg.png);
}
.translation-bg {
  background-image: url(../assets/img/static/bg/translateBg.png);
}

.notarization-bg {
  background-image: url(../assets/img/static/bg/notarization.png);
}
.application-process-bg {
  background-image: url(../assets/img/static/bg/applicationProcess.png);
}
.tips-for-abroad-bg {
  background-image: url(../assets/img/static/bg/tipsAbroad.png);
}
.resources-bg {
  background-image: url(../assets/img/static/bg/resourcesBg.png);
}
.requirement-bg {
  background-image: url(../assets/img/static/bg/requirement.png);
}
.about-us-bg {
  background-image: url(../assets/img/static/bg/aboutUs.png);
}

.filter-badge {
  position: relative;
  display: inline-block;
  &::after {
    position: absolute;
    content: "";
    border-radius: 50%;
    background-color: #a0489e;
    width: 40px;
    height: 40px;
    left: 0px;
    top: 0px;
    z-index: -1;
  }
}
.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: unset;
  display: block;
}
.custom-date-picker {
  background-color: #fff;
}

// Travel Abroad
.travel-abroad-header-image-text {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%) rotate(-13deg);
  z-index: -1;
  h4 {
    line-height: 0.9;
  }
  @media (max-width: 576px) {
    left: 10%;
  }
}
